import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, BreakoutContentRow, BreakoutContentCard, PageSection, RelatedContent, AccordionGroup, ArMgmtItem, TextBlock, ExclamationList } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';
import styled from 'styled-components';
import { createLocalScaleFunction } from '../../util/functions';

// SEO INFORMATION
const pageMeta = {
    title: `Monitoring and Managing Adverse Reactions to KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `managing keytruda adverse reactions, monitoring keytruda adverse reactions`,
    description: `Health care professionals can select from a list of adverse reactions to find specific information on how to help monitor and manage adverse reactions to KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/monitoring-managing-adverse-reactions/","@type":"MedicalWebPage","name":"Monitoring and Management of Immune-Mediated Adverse Reactions","description":"Withhold or permanently discontinue KEYTRUDA depending on severity of the immune-mediated adverse reaction."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/monitoring-managing-adverse-reactions/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const relatedContent = [
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/dose-modifications/',
            label: 'Dose Modifications'
        }
    }
]

// removing top margin for first-child of one-off accordion content
const Wrapper = styled.div`
    margin-right: ${createLocalScaleFunction(0, 320, 64, 992)};
    margin-left: ${createLocalScaleFunction(0, 320, 64, 992)};

    :nth-child(1){
        div {
            margin-top: 0;
        }
    }
`

const MnMitems = [
    // ( 1 ) Respiratory: Immune-Mediated Pneumonitis
    {
        title:"Immune-Mediated Pneumonitis",
        // subtitle:"Respiratory",
        children:(
            <ArMgmtItem 
                    adverseReaction="Immune-Mediated Pneumonitis"

                    gradeCalloutData={[
                        {   
                            header: "Grade 2",
                            description: "Withhold KEYTRUDA<sup>a</sup>"
                        },
                        {   
                            header: "Grade 3 or 4",
                            description: "Permanently discontinue KEYTRUDA"
                        },
                    ]}

                    mgmtFootnotes={[
                        {
                            label: "a.",
                            text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
                        }
                    ]}

                    patientCounselingInformation={[
                        { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                        { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },    
                        { description: "Please advise patients to contact their healthcare provider immediately for new or worsening cough, chest pain, or shortness of breath." },
                    ]}
                />
        ),
    },
    // ( 2 ) Gastrointestinal: Immune-Mediated Colitis
    {
        title:"Immune-Mediated Colitis",
        // subtitle:"Gastrointestinal",
        children:(
            <ArMgmtItem 
                adverseReaction="Immune-Mediated Colitis"

                monitoringData={[
                    { description: "Colitis may present with diarrhea." },
                    { description: "Cytomegalovirus (CMV) infection/reactivation has been reported in patients with corticosteroid-refractory immune-mediated colitis. In cases of corticosteroid-refractory colitis, consider repeating infectious workup to exclude alternative etiologies." }
                ]}

                gradeCalloutData={[
                    { 
                        header: "Grade 2 or 3",
                        description: "Withhold KEYTRUDA<sup>a</sup>"
                    },
                    { 
                        header: "Grade 4",
                        description: "Permanently discontinue KEYTRUDA"
                    }
                ]}

                mgmtFootnotes={[
                    {
                        label: "a.",
                        text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
                    }
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                    { description: "Please advise patients to contact their healthcare provider immediately for diarrhea or severe abdominal pain." },
                ]}
            />
        ),
    },
    // ( 3 ) Hepatic: Immune-Mediated Hepatitis
    {
        title:"Immune-Mediated Hepatitis",
        // subtitle:"Hepatic",
        children:(
            <ArMgmtItem 
                    adverseReaction="Immune-Mediated Hepatitis"

                    monitoringData={[
                        { description: "Evaluate liver enzymes at baseline and periodically during treatment." },
                        { description: 'For liver enzyme elevations in patients treated with combination therapy with axitinib, see below: "Hepatotoxicity and Liver Enzyme Elevations (KEYTRUDA in Combination With Axitinib)".' }
                    ]}

                    optionalMgmtTitleExtension="(no tumor involvement of the liver)"
                    gradeCalloutData={[
                        { 
                            header: "AST or ALT increases to >3 x and up to 8 x ULN",
                            description: "Withhold KEYTRUDA<sup>a</sup>"
                        },
                        { 
                            header: "Total bilirubin increases to >1.5 x and up to 3 x ULN",
                            description: "Withhold KEYTRUDA<sup>a</sup>"
                        },
                        { 
                            header: "AST or ALT increases to >8 x ULN",
                            description: "Permanently discontinue KEYTRUDA"
                        },
                        { 
                            header: "Total bilirubin increases to >3 x ULN",
                            description: "Permanently discontinue KEYTRUDA"
                        }
                    ]}
                    // 2nd section
                    optionalMgmtTitleExtensionExtra=" (with tumor involvement of the liver)<sup>b</sup>"
                    gradeCalloutDataExtra={[
                        { 
                            header: "Baseline AST or ALT is >1 x and up to 3 x ULN and increases to >5 x and up to 10 x ULN",
                            description: "Withhold KEYTRUDA<sup>a</sup>"
                        },
                        { 
                            header: "Baseline AST or ALT is >3 x and up to 5 x ULN and increases to >8 x and up to 10 x ULN",
                            description: "Withhold KEYTRUDA<sup>a</sup>"
                        },
                        { 
                            header: "ALT or AST increases to >10 x ULN",
                            description: "Permanently discontinue KEYTRUDA"
                        },
                        { 
                            header: "Total bilirubin increases to >3 x ULN",
                            description: "Permanently discontinue KEYTRUDA"
                        }
                    ]}

                    mgmtFootnotes={[
                        {
                            label: "a.",
                            text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
                        },
                        {
                            label: "b.",
                            text: "If AST and ALT are less than or equal to ULN at baseline, withhold or permanently discontinue KEYTRUDA based on recommendations for hepatitis with no liver involvement."
                        },
                    ]}

                    patientCounselingInformation={[
                        { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                        { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                        { description: "Please advise patients to contact their healthcare provider immediately for jaundice, severe nausea or vomiting, or easy bruising or bleeding." },
                    ]}

                    definitions="ALT = alanine aminotransferase; AST = aspartate aminotransferase; ULN = upper limit of normal."
                />

        ),
    },
    // ( 4 ) Hepatic: Hepatotoxicity and Liver Enzyme Elevation in Combination With Axitinib
    {
        title:"Hepatotoxicity and Liver Enzyme Elevations (KEYTRUDA in Combination With Axitinib)",
        // subtitle:"Hepatic",
        children:(
            <ArMgmtItem 
                adverseReaction="Hepatotoxicity/Liver Enzyme Elevations"
                monitoringData={[
                    { description: "KEYTRUDA in combination with axitinib can cause hepatic toxicity with higher than expected frequencies of Grades 3 and 4 ALT and AST elevations compared to KEYTRUDA alone." },
                    { description: "Monitor liver enzymes before initiation of and periodically throughout treatment." },
                    { description: "Consider more frequent monitoring of liver enzymes as compared to when the drugs are administered as single agents." }
                ]}

                gradeCalloutData={[
                    { 
                        header: "ALT or AST increases to at least 3 x ULN but <10 x ULN without concurrent total bilirubin at least 2 x ULN",
                        description: "Withhold both KEYTRUDA and axitinib until resolution to Grades 0–1<sup>a,b</sup>"
                    },
                    { 
                        header: "ALT or AST increases to >3 x ULN with concurrent total bilirubin at least 2 x ULN",
                        description: "Permanently discontinue both KEYTRUDA and axitinib<sup>b</sup>"
                    },
                    { 
                        header: "ALT or AST increases to ≥10 x ULN",
                        description: "Permanently discontinue both KEYTRUDA and axitinib<sup>b</sup>"
                    }
                ]}

                mgmtFootnotes={[
                    {
                        label: "a.",
                        text: "Based on Common Terminology Criteria for Adverse Events (CTCAE), version 4.0. Consider rechallenge with a single drug or sequential rechallenge with both drugs after recovery. If rechallenging with axitinib, consider dose reduction as per the axitinib Prescribing Information."
                    },
                    {
                        label: "b.",
                        text: "Consider corticosteroid therapy."
                    },
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." }, 
                    { description: "Please advise patients to contact their healthcare provider immediately for jaundice, severe nausea or vomiting, or easy bruising or bleeding." },
                ]}

                definitions="ALT = alanine aminotransferase; AST = aspartate aminotransferase; ULN = upper limit of normal."
            />
        ),
    },
    // ( 5 ) Endocrine: Adrenal insufficiency (Immune-Mediated Endocrinopathies)
    {
        title:"Adrenal Insufficiency (Immune-Mediated Endocrinopathies)",
        // subtitle:"Endocrine",
        children:(
            <ArMgmtItem 
                adverseReaction="Adrenal Insufficiency (Immune-Mediated Endocrinopathies)"

                monitoringData={[
                    { description: "KEYTRUDA can cause primary or secondary adrenal insufficiency." },
                    { description: "Monitor patients for signs and symptoms of adrenal insufficiency." }
                ]}

                gradeCalloutData={[
                    { 
                        header: "Grade 2 or Higher",
                        description: "For Grade 2 or higher adrenal insufficiency, initiate symptomatic treatment, including hormone replacement as clinically indicated. Withhold KEYTRUDA depending on severity."
                    },
                    { 
                        header: "Grade 3 or 4",
                        description: "Initiate symptomatic treatment, including hormone replacement as clinically indicated. Withhold until clinically stable or permanently discontinue depending on severity."
                    }
                ]}
                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." }, 
                    { description: "Advise patients to contact their healthcare provider immediately for signs or symptoms of adrenal insufficiency." }
                ]}
            />
        ),
    },
    // ( 6 ) Endocrine: Hypophysitis (Immune-Mediated Endocrinopathies)
    {
        title:"Hypophysitis (Immune-Mediated Endocrinopathies)",
        // subtitle:"Endocrine",
        children:(
            <ArMgmtItem 
                adverseReaction="Hypophysitis (Immune-Mediated Endocrinopathies)"

                monitoringData={[
                    { description: "Hypophysitis can present with acute symptoms associated with mass effect such as headache, photophobia, or visual field defects." },
                    { description: "Hypophysitis can cause hypopituitarism." }
                ]}

                arMgmtBullet={
                    `<ul>
                        <li>Initiate hormone replacement as indicated.</li>
                    </ul>
                `}

                gradeCalloutData={[
                    { 
                        header: "Grade 3 or 4",
                        description: "Withhold until clinically stable or permanently discontinue depending on severity"
                    }
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." }, 
                    { description: "Advise patients to contact their healthcare provider immediately for signs or symptoms of hypophysitis." }
                ]}
            />
        ),
    },
    // ( 7 ) Endocrine: Thyroid Disorders (Immune-Mediated Endocrinopathies)
    {
        title:"Thyroid Disorders (Immune-Mediated Endocrinopathies)",
        // subtitle:"Endocrine",
        children:(
            <ArMgmtItem 
                adverseReaction="Thyroid Disorders (Immune-Mediated Endocrinopathies)"

                monitoringData={[
                    { description: "Thyroid disorders can include thyroiditis, hyperthyroidism, and hypothyroidism." },
                    { description: "Thyroiditis can present with or without endocrinopathy." },
                    { description: "Hypothyroidism can follow hyperthyroidism." },
                    { description: "Evaluate thyroid function at baseline and periodically during treatment." },
                ]}

                arMgmtBullet={
                    `<ul>
                        <li>Initiate treatment (hormone replacement for hypothyroidism or institute medical management for hyperthyroidism) as clinically indicated.</li>
                    </ul>
                `}

                gradeCalloutData={[
                    { 
                        header: "Grade 3 or 4",
                        description: "Withhold until clinically stable or permanently discontinue depending on severity"
                    }
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." }, 
                    { description: "Advise patients to contact their healthcare provider immediately for signs or symptoms of hypothyroidism or hyperthyroidism." }
                ]}
            />
        ),
    },
    // ( 8 ) Endocrine: Type 1 Diabetes Mellitus (Immune-Mediated Endocrinopathies)
    {
        title:"Type 1 Diabetes Mellitus (DM), Which Can Present With Diabetic Ketoacidosis (Immune-Mediated Endocrinopathies)",
        // subtitle:"Endocrine",
        children:(
            <ArMgmtItem 
                adverseReaction="Type 1 Diabetes Mellitus (Immune-Mediated Endocrinopathies)"

                monitoringData={[
                    { description: "Monitor patients for hyperglycemia or other signs and symptoms of diabetes." },
                    { description: "Type 1 diabetes mellitus can present with diabetic ketoacidosis." },
                ]}

                arMgmtBullet={
                    `<ul>
                        <li>Initiate treatment with insulin as clinically indicated.</li>
                    </ul>
                `}

                gradeCalloutData={[
                    { 
                        header: "Grade 3 or 4",
                        description: "Withhold until clinically stable or permanently discontinue depending on severity"
                    }
                ]}
                
                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." }, 
                    { description: "Advise patients to contact their healthcare provider immediately for signs or symptoms of type 1 diabetes." },
                ]}

            />
        ),
    },
    // ( 9 ) Renal: Immune-Mediated Nephritis with Renal Dysfunction
    {
        title:"Immune-Mediated Nephritis With Renal Dysfunction",
        // subtitle:"Renal",
        children:(
            <ArMgmtItem 
                adverseReaction="Immune-Mediated Nephritis With Renal Dysfunction"

                monitoringData={[
                    { description: "Evaluate creatinine at baseline and periodically during treatment." }
                ]}

                gradeCalloutData={[
                    { 
                        header: "Grade 2 or 3 increased blood creatinine",
                        description: "Withhold KEYTRUDA<sup>a</sup>"
                    },
                    { 
                        header: "Grade 4 increased blood creatinine",
                        description: "Permanently discontinue KEYTRUDA"
                    }
                ]}

                mgmtFootnotes={[
                    {
                        label: "a.",
                        text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
                    },
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                    { description: "Advise patients to contact their healthcare provider immediately for signs or symptoms of nephritis." }
                ]}
            />
        ),
    },
    // ( 10 ) Dermatologic: Immune-Mediated Dermatologic Adverse Reactions
    {
        title:"Immune-Mediated Dermatologic Adverse Reactions",
        // subtitle:"Dermatologic",
        children:(
            <ArMgmtItem 
                adverseReaction="Dermatologic Adverse Reactions"

                monitoringData={[
                    { description: "KEYTRUDA can cause immune-mediated rash or dermatitis." },
                    { description: "Exfoliative dermatitis, including Stevens-Johnson syndrome, DRESS, and toxic epidermal necrolysis (TEN), has occurred with PD-1/PD-L1 blocking antibodies. Monitor patients for signs and symptoms of suspected severe skin reactions." },
                ]}

                arMgmtBullet="<p>Topical emollients and/or topical corticosteroids may be adequate to treat mild to moderate nonexfoliative rashes. Withhold or permanently discontinue KEYTRUDA depending on severity.</p>"

                gradeCalloutData={[
                    { 
                        header: "Exfoliative Dermatologic Conditions: Suspected SJS, TEN, or DRESS",
                        description: "Withhold KEYTRUDA<sup>a</sup>"
                    },
                    { 
                        header: "Exfoliative Dermatologic Conditions: Confirmed SJS, TEN, or DRESS",
                        description: "Permanently discontinue KEYTRUDA"
                    }
                ]}

                mgmtFootnotes={[
                    {
                        label: "a.",
                        text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
                    },
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                    { description: "Advise patients to contact their healthcare provider immediately for any signs or symptoms of severe skin reactions, SJS, or TEN." }
                ]}

                definitions="DRESS = drug rash with eosinophilia and systemic symptoms; PD&#8288;-&#8288;1 = programmed death receptor-1; PD&#8288;-&#8288;L1 = programmed death ligand 1."
            />
        ),
    },
    // ( 11 ) Cardiac/Vascular: Myocarditis
    {
        title:"Immune-Mediated Myocarditis",
        // subtitle:"Cardiac/Vascular",
        children:(
            <ArMgmtItem 
                adverseReaction="Myocarditis"

                gradeCalloutData={[
                    { 
                        header: "Grade 2, 3, or 4",
                        description: "Permanently discontinue KEYTRUDA"
                    }
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                ]}
            />
        ),
    },
    // ( 12 ) Neurological: Immune-Mediated Neurological Toxicities
    {
        title:"Immune-Mediated Neurological Toxicities",
        // subtitle:"Neurologic",
        children:(
            <ArMgmtItem 
            adverseReaction="Neurological Toxicities"

            gradeCalloutData={[
                { 
                    header: "Grade 2",
                    description: "Withhold KEYTRUDA<sup>a</sup>"
                },
                { 
                    header: "Grade 3 or 4",
                    description: "Permanently discontinue KEYTRUDA"
                }
            ]}

            mgmtFootnotes={[
                {
                    label: "a.",
                    text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
                },
            ]}

            patientCounselingInformation={[
                { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
            ]}
        />
        ),
    },
    // ( 13 ) Hematologic: Hematologic toxicity in patients with cHL or PMBCL
    {
        title:"Immune-Mediated Hematologic Toxicity in Patients With cHL or PMBCL",
        // subtitle:"Hematologic",
        children:(
            <ArMgmtItem 
                adverseReaction="Hematologic Toxicity in Patients With cHL or PMBCL"

                gradeCalloutData={[
                    { 
                        header: "Grade 4",
                        description: "Withhold KEYTRUDA until resolution to Grades 0 or 1"
                    }
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                ]}

                definitions="cHL = classical Hodgkin lymphoma; PMBCL = primary mediastinal large B-cell lymphoma."
            />
        ),
    },
    // ( Pastel 188: Move 16 ) Other Immune-Mediated Adverse Reactions (IMARs)
    {
        title:"Other Immune-Mediated Adverse Reactions (IMARs)",
        // subtitle:"Cardiac/Vascular; Nervous System; Ocular; Gastrointestinal; Musculoskeletal and Connective Tissue; Endocrine; Hematologic/Immune",
        children:(
            <Wrapper>
                <TextBlock definitions="PD&#8288;-&#8288;1 = programmed death receptor-1; PD&#8288;-&#8288;L1 = programmed death ligand 1.">
                    <h3>Other Immune-Mediated Adverse Reactions (IMARs)</h3>
                    <p>The following clinically significant IMARs occurred in patients who received KEYTRUDA or were reported with the use of other PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 blocking antibodies. Severe or fatal cases have been reported for some of these adverse reactions.</p>
                    <ul>
                        <li><i>Cardiac/Vascular:</i> Myocarditis, pericarditis, vasculitis</li>
                        <li><i>Nervous System:</i> Meningitis, encephalitis, myelitis and demyelination, myasthenic syndrome/myasthenia gravis (including exacerbation), Guillain-Barré syndrome, nerve paresis, autoimmune neuropathy</li>
                        <li><i>Ocular:</i> Uveitis, iritis and other ocular inflammatory toxicities can occur. Some cases can be associated with retinal detachment. Various grades of visual impairment, including blindness, can occur. If uveitis occurs in combination with other immune-mediated adverse reactions, consider a Vogt-Koyanagi-Harada-like syndrome, as this may require treatment with systemic steroids to reduce the risk of permanent vision loss</li>
                        <li><i>Gastrointestinal:</i> Pancreatitis, to include increases in serum amylase and lipase levels, gastritis, duodenitis</li>
                        <li><i>Musculoskeletal and Connective Tissue:</i> Myositis/polymyositis, rhabdomyolysis (and associated sequelae, including renal failure), arthritis, polymyalgia rheumatica</li>
                        <li><i>Endocrine:</i> Hypoparathyroidism</li>
                        <li><i>Hematologic/Immune:</i> Hemolytic anemia, aplastic anemia, hemophagocytic lymphohistiocytosis, systemic inflammatory response syndrome, histiocytic necrotizing lymphadenitis (Kikuchi lymphadenitis), sarcoidosis, immune thrombocytopenic purpura, solid organ transplant rejection, other transplant (including corneal graft) rejection</li>
                    </ul>
                </TextBlock>
                    <ExclamationList 
                    arTitle={true}
                    title="Patient Counseling Information"
                    items={[
                        { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                        { description: "Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA." },
                        { description: "Advise patients that immune-mediated adverse reactions can occur and may involve any organ system, and to contact their healthcare provider immediately for any new or worsening signs or symptoms." },
                        { description: "Advise patients of the risk of solid organ transplant rejection and other transplant (including corneal graft) rejections. Advise patients to contact their healthcare provider immediately for signs or symptoms of organ transplant rejection and other transplant (including corneal graft) rejection." }
                    ]}
                />
                
            </Wrapper>
        ),
    },
    // ( 14 ) Infusion-Related Reactions
    {
        title:"Infusion-Related Reactions",
        children:(
            <ArMgmtItem 
                adverseReaction="Infusion-Related Reactions"

                monitoringData={[
                    { description: "KEYTRUDA can cause severe or life-threatening infusion-related reactions, including hypersensitivity and anaphylaxis." },
                    { description: "Monitor patients for signs and symptoms of infusion-related reactions including rigors, chills, wheezing, pruritus, flushing, rash, hypotension, hypoxemia, and fever." }
                ]}

                gradeCalloutData={[
                    { 
                        header: "Grade 1 or 2",
                        description: "Interrupt or slow the rate of infusion"
                    },
                    { 
                        header: "Grade 3 or 4",
                        description: "Stop infusion and permanently discontinue KEYTRUDA"
                    }
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Advise patients to contact their healthcare provider immediately for signs or symptoms of infusion-related reactions." }
                ]}
            />
        ),
    },
    // ( 15 ) Complications of Allogeneic Hematopoietic Stem Cell Transplantation (HSCT)
    {
        title:"Complications of Allogeneic Hematopoietic Stem Cell Transplantation (HSCT)",
        children:(
            <ArMgmtItem 
                adverseReaction="Complications of Allogeneic HSCT"

                monitoringData={[
                    { description: "Fatal and other serious complications can occur in patients who receive allogeneic HSCT before or after being treated with a PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 blocking antibody." },
                    { description: "Transplant-related complications include hyperacute graft-versus-host disease (GVHD), acute GVHD, chronic GVHD, hepatic veno-occlusive disease (VOD) after reduced intensity conditioning, and steroid-requiring febrile syndrome (without an identified infectious cause)." },
                    { description: "These complications may occur despite intervening therapy between PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 blockade and allogeneic HSCT." }
                ]}

                recommendationData={[
                    { description: "Follow patients closely for evidence of transplant-related complications and intervene promptly." },
                    { description: "Consider the benefit vs risks of treatment with a PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 blocking antibody prior to or after an allogeneic HSCT." },
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Advise patients of the risk of post-allogeneic hematopoietic stem cell transplantation complications." }
                ]}

                definitions="PD&#8288;-&#8288;1 = programmed death receptor-1; PD&#8288;-&#8288;L1 = programmed death ligand 1."
            />
        ),
    },
    // ( Pastel 175 ) 
    {
        title: "Increased Mortality in Patients With Multiple Myeloma When KEYTRUDA Is Added to a Thalidomide Analogue and Dexamethasone",
        children:(
            <ArMgmtItem 
                adverseReaction="Increased Mortality in Patients With Multiple Myeloma When KEYTRUDA Is Added to a Thalidomide Analogue and Dexamethasone"

                monitoringTitle="Considerations"

                monitoringData={[
                    { description: "In 2 randomized trials in patients with multiple myeloma, the addition of KEYTRUDA to a thalidomide analogue plus dexamethasone, a use for which no PD-1 or PD-L1 blocking antibody is indicated, resulted in increased mortality. Treatment of patients with multiple myeloma with a PD-1 or PD-L1 blocking antibody in combination with a thalidomide analogue plus dexamethasone is not recommended outside of controlled trials." },
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                ]}
                />
        )
    },
    // ( 17 ) Pregnancy: Embryo-Fetal Toxicity
    {
        title:"Embryo-Fetal Toxicity",
        // subtitle:"Pregnancy",
        children:(
            <ArMgmtItem 
                adverseReaction="Embryo-Fetal Toxicity"

                monitoringTitle="Considerations"

                monitoringData={[
                    { description: "Based on its mechanism of action, KEYTRUDA can cause fetal harm when administered to a pregnant woman." },
                    { description: "Verify pregnancy status in females of reproductive potential prior to initiating KEYTRUDA." },
                ]}

                patientCounselingInformation={[
                    { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                    { description: "Advise females of reproductive potential of the potential risk to a fetus and to inform their healthcare provider of a known or suspected pregnancy." },
                    { description: "Advise females of reproductive potential to use effective contraception during treatment with KEYTRUDA and for 4 months after the last dose." },
                ]}
                />
        ),
    },
        // ( 18 ) Lactation
        {
            title:"Lactation",
            children:(
                <ArMgmtItem 
                    adverseReaction="Lactation"
    
                    monitoringTitle="Considerations"
                    
                    monitoringData={[
                        { description: "There are no data on the presence of pembrolizumab in either animal or human milk or its effects on the breastfed child or on milk production. " },
                    ]}
    
                    patientCounselingInformation={[
                        { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." },
                        { description: "Because of the potential for serious adverse reactions in breastfed children, advise women not to breastfeed during treatment with KEYTRUDA and for 4 months after the last dose." },
                    ]}
                    />
            ),
        },
        // ( 19 ) 
        {
            title:"Pediatric Use",
            children:(
                <ArMgmtItem 
                    adverseReaction="Pediatric Use"

                    monitoringData={[
                        { description: "The safety and effectiveness of KEYTRUDA as a single agent have been established in pediatric patients with melanoma, cHL, PMBCL, MCC, and MSI&#8288;-&#8288;H or dMMR cancer. Use of KEYTRUDA in pediatric patients for these indications is supported by evidence from adequate and well-controlled studies in adults with additional pharmacokinetic and safety data in pediatric patients." },
                        { description: "In KEYNOTE&#8288;-&#8288;051, 173 pediatric patients (65 pediatric patients aged 6 months to younger than 12 years and 108 pediatric patients aged 12 years to 17 years) were administered KEYTRUDA 2&nbsp;mg/kg every 3 weeks. The median duration of exposure was 2.1 months (range: 1 day to 25 months)." },
                        { description: "Adverse reactions that occurred at a ≥10% higher rate in pediatric patients when compared to adults were pyrexia (33%), leukopenia (31%), vomiting (29%), neutropenia (28%), headache (25%), abdominal pain (23%), thrombocytopenia (22%), Grade 3 anemia (17%), decreased lymphocyte count (13%), and decreased white blood cell count (11%)." },
                    ]}

                    patientCounselingInformation={[
                        { description: "Advise patients to read the FDA-approved patient labeling (Medication Guide)." }
                    ]}

                    definitions="cHL = classical Hodgkin lymphoma; dMMR = mismatch repair deficient; MSI&#8288;-&#8288;H = microsatellite instability-high; PMBCL = primary mediastinal large B-cell lymphoma."
                    />
            ),
        },
        {
            title:"Geriatric Use",
            children:(
                <Wrapper>
                <TextBlock>
                    <h3>Geriatric Use</h3>
                    <p>Of the 564 patients with locally advanced or metastatic urothelial cancer treated with KEYTRUDA in combination with enfortumab vedotin, 44% (n=247) were 65-74 years and 26% (n=144) were 75 years or older. No overall differences in safety or effectiveness were observed between patients 65 years of age or older and younger patients. Patients 75 years of age or older treated with KEYTRUDA in combination with enfortumab vedotin experienced a higher incidence of fatal adverse reactions than younger patients. The incidence of fatal adverse reactions was 4% in patients younger than 75 and 7% in patients 75 years or older.</p>
                </TextBlock>
                </Wrapper>
            ),
        },
]

const Page = ({ location }) => {
    return (
        <DefaultLayout jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-c">
                <TemplateColumn id="template-c-intro">
                    <PageHeader title="Monitoring and Management of Immune-Mediated Adverse Reactions" />
                    <PageSection>
                        <BreakoutContentRow definitions="TNBC = triple-negative breast cancer.">
                            <BreakoutContentCard 
                                icon={() => <img src="/static/images/badge-clipboard.png" 
                                alt="" 
                                width={60} 
                                height={60} />} 
                                title="Monitor patients closely for symptoms and signs that may be clinical manifestations of underlying immune-mediated adverse reactions."
                            >
                                <ul>
                                    <li>Early identification and management are essential to ensure safe use of anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments.</li>
                                    <li>Evaluate liver enzymes, creatinine, and thyroid function at baseline and periodically during treatment.</li>
                                    <li>For patients with TNBC treated with KEYTRUDA in the neoadjuvant setting, monitor blood cortisol at baseline, prior to surgery, and as clinically indicated.</li>
                                    <li>In cases of suspected immune-mediated adverse reactions, initiate appropriate workup to exclude alternative etiologies, including infection.</li>
                                    <li>Institute medical management promptly, including specialty consultation as appropriate.</li>
                                </ul>
                            </BreakoutContentCard>
                            <BreakoutContentCard 
                                icon={() => <img src="/static/images/badge-safety.png" 
                                alt="" 
                                width={60} 
                                height={60} />} 
                                title="Withhold or permanently discontinue KEYTRUDA depending on severity of the immune-mediated adverse reaction."
                            >
                                <ul>
                                    <li>In general, if KEYTRUDA requires interruption or discontinuation, administer systemic corticosteroid therapy (1 to 2&nbsp;mg/kg/day prednisone or equivalent) until improvement to Grade 1 or less.</li>
                                    <li>Upon improvement to Grade 1 or less, initiate corticosteroid taper and continue to taper over at least 1 month.</li>
                                    <li>Consider administration of other systemic immunosuppressants in patients whose adverse reactions are not controlled with corticosteroid therapy.</li>
                                    <li>Toxicity management guidelines for adverse reactions that do not necessarily require systemic steroids (eg, endocrinopathies and dermatologic reactions) are discussed below.</li>
                                </ul>
                            </BreakoutContentCard>
                            <BreakoutContentCard 
                                icon={() => <img src="/static/images/badge-iv-3-weeks.svg" 
                                alt="" 
                                width={60} 
                                height={60} />} 
                                title="Dose Modifications"
                            >
                                <ul>
                                    <li><b>No dose reduction for KEYTRUDA is recommended.</b></li>
                                    <li>In general, withhold KEYTRUDA for severe (Grade 3) immune-mediated adverse reactions.</li>
                                    <li>Permanently discontinue KEYTRUDA for life-threatening (Grade 4) immune-mediated adverse reactions, recurrent severe (Grade 3) immune-mediated reactions that require systemic immunosuppressive treatment, or an inability to reduce corticosteroid dose to 10&nbsp;mg or less of prednisone or equivalent per day within 12 weeks of initiating steroids.</li>
                                    <li>Dosage modifications for KEYTRUDA for adverse reactions that require management different from these general guidelines are included below.</li>
                                </ul>
                            </BreakoutContentCard>
                        </BreakoutContentRow>
                    </PageSection>
                </TemplateColumn>

                <TemplateColumn id="template-c-main">
                    <PageSection title="Recommended Dosage Modifications, Toxicity Management Guidelines, and Patient Counseling Information" bgColor="cloud">
                        <TextBlock>
                            <p>Toxicity management guidelines for selected adverse reactions that do not necessarily require systemic steroids (eg, endocrinopathies and dermatologic reactions) are also included below.</p>
                            <p>The severity grades for adverse reactions are based on Common Terminology Criteria for Adverse Events (CTCAE), version 4.0.</p>
                        </TextBlock>
                        <AccordionGroup 
                            items={MnMitems}
                        />
                    </PageSection>

                    <PageSection bgColor='keytrudaGreen'>
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>

            </div>
        </DefaultLayout>
    );
};

export default Page;
